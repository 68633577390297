import { i18n } from '@lingui/core';
import moment from 'moment';
import { ConsistencyTestFormValuesUS } from './models';
import { FormInstance } from 'antd';
import { parseDecimalByLocale } from 'src/app-react/utils/formatters';
import { getProjectConstants } from 'src/app-react/services/projectService';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { MARKETS } from 'src/app-react/constants/markets';
import {
    ConsistencyTest,
    FreshConcreteTest
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createInitialValues(values: any) {
    const initialFormValuesUS: ConsistencyTestFormValuesUS = values;

    return {
        testingDate:
            initialFormValuesUS?.testingDate !== undefined ? moment(initialFormValuesUS.testingDate) : undefined,
        testingTime:
            initialFormValuesUS?.testingTime !== undefined ? moment(initialFormValuesUS.testingTime) : undefined,
        flowTableTest: initialFormValuesUS?.flowTableTest?.toString()
    };
}

export function getConsistencyFormValuesUS(consistencyTestForm: FormInstance<any>): ConsistencyTestFormValuesUS {
    return {
        testingDate: (consistencyTestForm.getFieldValue('testingDate') as moment.Moment).toDate(),
        testingTime: (consistencyTestForm.getFieldValue('testingTime') as moment.Moment).toDate(),
        flowTableTest: parseDecimalByLocale(consistencyTestForm.getFieldValue('flowTableTest') as string, i18n.locale),
        addedWaterOnSite: parseDecimalByLocale(
            consistencyTestForm.getFieldValue('addedWaterOnSite') as string,
            i18n.locale
        )
    };
}

export function createConsistencyTestUS(
    freshConcreteTest: FreshConcreteTest,
    consistencyTestFormValues: ConsistencyTestFormValuesUS
) {
    const { units } = getProjectConstants({ marketId: MARKETS.US });
    const testingTime: Date = mergeDateTime(
        moment(consistencyTestFormValues.testingDate),
        moment(consistencyTestFormValues.testingTime)
    ).toDate();

    const consistencyTest: ConsistencyTest = {
        ...freshConcreteTest,
        diameter: {
            length: consistencyTestFormValues.flowTableTest,
            unit: units.slump as ConsistencyTest['diameter']['unit']
        },
        addedWaterOnSite: consistencyTestFormValues.addedWaterOnSite,
        testingTime,
        externalInspection: false
    };

    return consistencyTest;
}
