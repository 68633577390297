import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import { IMarketUnits } from 'src/app-react/constants/marketsData';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { ConcreteTestingTypes } from '../../utils/concrete-tests-service';
import { RelativeHumidityTest, WindSpeedTest } from '@nexploretechnology/concreting-core-client';
import { MARKETS } from 'src/app-react/constants/markets';
import {
    AirContentTest,
    ConsistencyTest,
    FreshConcreteTest,
    TemperatureTest
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export interface IFreshConcreteTableColumnsProps {
    onEdit: (freshConcreteTest: FreshConcreteTest) => void;
    onDelete: (freshConcreteTest: FreshConcreteTest) => void;
    onDetails: (freshConcreteTest: FreshConcreteTest) => void;
    marketId: string;
    units: IMarketUnits;
}

type FixedType = boolean | 'right' | 'left';

export const freshConcreteTableColumns: (props: IFreshConcreteTableColumnsProps) => ColumnsType<FreshConcreteTest> = (
    props: IFreshConcreteTableColumnsProps
) => {
    const { onEdit, onDelete, marketId, units } = props;
    const { isViewer } = useUserPermissions();

    return [
        {
            title: <Trans>Test Type</Trans>,
            dataIndex: 'testType',
            sorter: (a: FreshConcreteTest, b: FreshConcreteTest) => {
                return determingTestingType(a).localeCompare(determingTestingType(b));
            },
            render: renderTestTypeName
        },
        {
            title: <Trans>Sampling time</Trans>,
            dataIndex: 'samplingTime',
            sorter: (a: FreshConcreteTest, b: FreshConcreteTest) => {
                return new Date(a.samplingTime).getTime() - new Date(b.samplingTime).getTime();
            },
            defaultSortOrder: 'ascend' as SortOrder,
            render: (samplingTime: Date) => (
                <Space direction="vertical">
                    <DateTimeFormatter date={samplingTime} />
                </Space>
            )
        },
        {
            title: <Trans>Testing time</Trans>,
            dataIndex: 'testingTime',
            sorter: (a: FreshConcreteTest, b: FreshConcreteTest) => {
                return new Date(a.testingTime).getTime() - new Date(b.testingTime).getTime();
            },
            render: (testingTime: Date) => (
                <Space direction="vertical">
                    <DateTimeFormatter date={testingTime} />
                </Space>
            )
        },
        {
            title: <Trans>Test results</Trans>,
            dataIndex: 'createdOn', // we use another property for dataIndex, because we don't have the actual property will be available
            render: (_createdOn: Date, record: FreshConcreteTest) => renderTestResults(record, marketId, units)
        },
        {
            title: <Trans>External inspection</Trans>,
            dataIndex: 'externalInspection',
            hidden: marketId !== MARKETS.DE,
            sorter: (a: FreshConcreteTest, b: FreshConcreteTest) => {
                return Number(a.externalInspection ? 1 : 0) - Number(b.externalInspection ? 1 : 0);
            },
            render: (externalInspection: boolean) => <>{externalInspection ? t`yes` : t`no`}</>
        },
        {
            title: <Trans>Actions</Trans>,
            dataIndex: 'id',
            fixed: 'right' as FixedType,
            hidden: isViewer,
            width: 200,
            render: (_id: string, record: FreshConcreteTest) => (
                <Space direction="horizontal">
                    <Button data-testid="editButton" type="link" size="small" onClick={() => onEdit(record)}>
                        <EditOutlined /> <Trans>Edit</Trans>
                    </Button>

                    <Button data-testid="deleteButton" type="link" size="small" onClick={() => onDelete(record)}>
                        <DeleteOutlined /> <Trans>Delete</Trans>
                    </Button>
                </Space>
            )
        }
    ].filter((item) => !item.hidden);
};

function renderTestResults(record: FreshConcreteTest, marketId: string, units: IMarketUnits): JSX.Element {
    switch (determingTestingType(record)) {
        case ConcreteTestingTypes.Temperature:
            return renderTemperatureTestResults(record as TemperatureTest, marketId, units);
        case ConcreteTestingTypes.Consistency:
            return renderConsistencyTestResults(record as ConsistencyTest, units);
        case ConcreteTestingTypes.AirContent:
            return renderAirContentTestResults(record as AirContentTest);
        case ConcreteTestingTypes.WindSpeed:
            return renderWindSpeedTestResults(record as WindSpeedTest);
        case ConcreteTestingTypes.RelativeHumidity:
            return renderRelativeHumidityTestResults(record as RelativeHumidityTest);
        default:
            return null;
    }
}

function renderTemperatureTestResults(
    temperatureTest: TemperatureTest,
    marketId: string,
    units: IMarketUnits
): JSX.Element {
    return (
        <>
            <NumberFormatter value={temperatureTest.airTemprature.quantity} unit={units.temperature} />
            {' / '}
            <NumberFormatter value={temperatureTest.freshConcreteTemprature.quantity} unit={units.temperature} />
        </>
    );
}

function renderAirContentTestResults(airContentTest: AirContentTest) {
    return <NumberFormatter value={airContentTest.airContent} unit="Vol.%" />;
}

function renderWindSpeedTestResults(windSpeed: WindSpeedTest) {
    return <NumberFormatter value={windSpeed.windSpeed} unit={t`kph`} />;
}

function renderRelativeHumidityTestResults(windSpeed: RelativeHumidityTest) {
    return <NumberFormatter value={windSpeed.relativeHumidity} unit="%" />;
}

function renderConsistencyTestResults(consistencyTest: ConsistencyTest, units: IMarketUnits): JSX.Element {
    return (
        <>
            <NumberFormatter value={consistencyTest.diameter.length} unit={units.slump} />
            {consistencyTest.addedWaterOnSite && (
                <>
                    &nbsp;&#47; <NumberFormatter value={consistencyTest.addedWaterOnSite} unit={units.water} />
                    &nbsp;of water added on site
                </>
            )}
        </>
    );
}

function renderTestTypeName(_testType: string, record: FreshConcreteTest): JSX.Element {
    switch (determingTestingType(record)) {
        case ConcreteTestingTypes.Temperature:
            return <Trans id="Temperature">{ConcreteTestingTypes.Temperature}</Trans>;
        case ConcreteTestingTypes.Consistency:
            return <Trans id="Consistency">{ConcreteTestingTypes.Consistency}</Trans>;
        case ConcreteTestingTypes.AirContent:
            return <Trans id="Air Content">Air Content</Trans>;
        case ConcreteTestingTypes.WindSpeed:
            return <Trans id="Wind Speed">Wind Speed</Trans>;
        case ConcreteTestingTypes.RelativeHumidity:
            return <Trans id="Relative Humidity">Relative Humidity</Trans>;
        default:
            return <Trans id="Specimen">{ConcreteTestingTypes.Specimen}</Trans>;
    }
}

function determingTestingType(concreteTest: FreshConcreteTest): ConcreteTestingTypes {
    const test = { ...concreteTest };

    if (test.hasOwnProperty('airTemprature')) {
        return ConcreteTestingTypes.Temperature;
    }
    if (test.hasOwnProperty('diameter')) {
        return ConcreteTestingTypes.Consistency;
    }

    if (test.hasOwnProperty('airContent')) {
        return ConcreteTestingTypes.AirContent;
    }

    if (test.hasOwnProperty('windSpeed')) {
        return ConcreteTestingTypes.WindSpeed;
    }

    if (test.hasOwnProperty('relativeHumidity')) {
        return ConcreteTestingTypes.RelativeHumidity;
    }
    return ConcreteTestingTypes.Specimen;
}
