import { Moment } from 'moment';
import ConcreteLoadDataLiteFormFields from '../../models/ConcreteLoadDataLiteFormFields';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { SupplierConcreteLoadDataLite } from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';

function parseDate(date?: Moment) {
    if (date) {
        return date.toISOString() as unknown as Date;
    }

    return undefined;
}

function mergeDates(date: Moment, time?: Moment) {
    if (!date || !time) {
        return undefined;
    }

    return parseDate(mergeDateTime(date, time));
}

function parseNumber(number?: unknown) {
    if (typeof number === 'string') {
        if (number.length === 0) {
            return undefined;
        }

        return Number(number);
    }

    if (typeof number === 'number') {
        return number;
    }

    return undefined;
}

function parseXfFirstXfSecond(requiredXf?: string) {
    const splitValue = requiredXf?.split('/');

    return {
        xfFirst: typeof splitValue?.[0] === 'string' ? Number(splitValue[0]) : undefined,
        xfSecond: typeof splitValue?.[1] === 'string' ? Number(splitValue[1]) : undefined
    };
}

function parseRequiredX0(formFields: ConcreteLoadDataLiteFormFields) {
    if (formFields.requiredX0 === false || formFields.requiredX0 == null) {
        if (
            formFields.requiredXa != null ||
            formFields.requiredXm != null ||
            formFields.requiredXs != null ||
            formFields.requiredXc != null ||
            formFields.requiredXd != null ||
            formFields.requiredXf != null
        ) {
            return false;
        }

        return undefined;
    }

    return formFields.requiredX0;
}

function fromFormFieldsToSupplierConcreteLoadDataLite(formFields: ConcreteLoadDataLiteFormFields) {
    const concreteLoadDataLite: SupplierConcreteLoadDataLite = {
        supplierOrderId: formFields.supplierOrderId || undefined,
        deliveryTime: parseDate(formFields.deliveryTime),
        deliveryNoteNumber: formFields.deliveryNoteNumber,
        number: formFields.number,
        volume: parseNumber(formFields.volume),
        orderVolume: parseNumber(formFields.orderVolume),
        concretedVolume: parseNumber(formFields.concretedVolume),
        truckNumber: formFields.truckNumber || undefined,
        mixingPlant: formFields.mixingPlant || undefined,
        externalCustomProp: formFields.externalCustomProp || undefined,
        arrivalAtConstructionSite: mergeDates(
            formFields.arrivalAtConstructionSiteDate,
            formFields.arrivalAtConstructionSite
        ),
        loadingStart: mergeDates(formFields.loadingStartDate, formFields.loadingStart),
        // loadingEnd,
        unloadingStart: mergeDates(formFields.unloadingStartDate, formFields.unloadingStart),
        unloadingEnd: mergeDates(formFields.unloadingEndDate, formFields.unloadingEnd),
        signedOn: mergeDates(formFields.signedOnDate, formFields.signedOn),
        weather: formFields.signedOn ? formFields.weather : undefined,
        locationType: formFields.locationType || undefined,
        locationName: formFields.locationName || undefined,
        locationDescription: formFields.locationDescription || undefined,
        locationDistanceToPlant: formFields?.locationDistanceToPlant,
        constructorName: formFields?.constructorName,
        constructorNumber: formFields?.constructorNumber,
        contact: formFields?.contact,
        externalReference: formFields?.externalReference,
        supplierBusinessNumber: formFields?.supplierBusinessNumber,
        supplierName: formFields?.supplierName,
        additionalQuantityRequired: formFields?.additionalQuantityRequired,
        producedConcrete: {
            typeOfConcrete: formFields.compressiveStrengthClass?.requiredTypeOfConcreteType,
            cylindricCompressiveStrength: parseNumber(
                formFields.compressiveStrengthClass?.cylindricCompressiveStrength
            ),
            cubicCompressiveStrength: parseNumber(formFields.compressiveStrengthClass?.cubicCompressiveStrength),
            x0: parseRequiredX0(formFields),
            xa: formFields.requiredXa ?? undefined,
            xm: formFields.requiredXm ?? undefined,
            xs: formFields.requiredXs ?? undefined,
            xc: formFields.requiredXc ?? undefined,
            xd: formFields.requiredXd ?? undefined,
            ...parseXfFirstXfSecond(formFields.requiredXf),
            densityClass: formFields.densityClass,
            maxAggregationSize: parseNumber(formFields.maxAggregateSize),
            methodOfConcrete: formFields.methodOfConcrete,
            chlorideContent: formFields.chlorideContentClass,
            consistencyType: formFields.consistencyClass?.consistencyType,
            consistencyClass: parseNumber(formFields.consistencyClass?.consistencyClass),
            moistureClass: formFields.moistureClass,
            strengthDevelopmentClass: formFields.strengthDevelopmentClass,

            // US
            compressiveStrength: parseNumber(formFields.compressiveStrength),
            slump: parseNumber(formFields.slump),
            maxWaterToAdd: parseNumber(formFields.maxWaterToAdd),

            description: formFields.description
        }
    } as SupplierConcreteLoadDataLite;

    return concreteLoadDataLite;
}

export default fromFormFieldsToSupplierConcreteLoadDataLite;
