import moment from 'moment';
import { mergeDateTime } from '../../../utils/lib';
import { TestSampling } from '../components/MultipleConcreteTestModal/components/CommonTestForm/models';
import { SamplingLocationBP } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export enum ConcreteTestingTypes {
    Temperature = 'Temperature',
    Consistency = 'Consistency',
    Specimen = 'Specimen',
    AirContent = 'AirContent',
    WindSpeed = 'WindSpeed',
    RelativeHumidity = 'RelativeHumidity'
}

export interface FreshConcreteTest {
    id?: string;
    projectId: string;
    companyId: string;
    orderId: string;
    concreteLoadId: string;
    samplingLocation: SamplingLocationBP;
    samplingTime: Date;
    testingTime: Date;
    testType?: ConcreteTestingTypes;
    externalInspection: boolean;
    createdOn?: Date;
    lastModifiedOn?: Date;
}

interface DiameterMeasure {
    length: number;
    unit: 'mm' | 'm' | 'in';
}

export interface ConsistencyTest extends FreshConcreteTest {
    diameter: DiameterMeasure;
    addedWaterOnSite?: number;
}

export function newFreshConcreteTest(
    companyId: string,
    projectId: string,
    orderId: string,
    concreteLoadId: string,
    samplingValues: TestSampling
): FreshConcreteTest {
    const samplingTime: Date = mergeDateTime(
        moment(samplingValues.samplingDate),
        moment(samplingValues.samplingTime)
    ).toDate();

    const freshConcreteTest: Partial<FreshConcreteTest> = {
        projectId,
        companyId,
        orderId,
        concreteLoadId,
        samplingLocation: samplingValues.samplingLocation,
        samplingTime
    };

    return freshConcreteTest as FreshConcreteTest;
}
