import moment from 'moment';
import { ConsistencyTestFormValuesAUS } from './models';
import { getProjectConstants } from 'src/app-react/services/projectService';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { MARKETS } from 'src/app-react/constants/markets';
import { ConsistencyTest, FreshConcreteTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createConsistencyTestAUS(
    freshConcreteTest: FreshConcreteTest,
    consistencyTestFormValues: ConsistencyTestFormValuesAUS
) {
    const { units } = getProjectConstants({ marketId: MARKETS.AUS });
    const testingTime: Date = mergeDateTime(
        moment(consistencyTestFormValues.testingDate),
        moment(consistencyTestFormValues.testingTime)
    ).toDate();

    const consistencyTest: ConsistencyTest = {
        ...freshConcreteTest,
        diameter: {
            length: consistencyTestFormValues.flowTableTest,
            unit: units.slump as ConsistencyTest['diameter']['unit']
        },
        addedWaterOnSite: consistencyTestFormValues.addedWaterOnSite,
        testingTime,
        externalInspection: false
    };

    return consistencyTest;
}
