/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { t } from '@lingui/macro';
import InputFormItem, { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import InputNumber from 'src/app-react/components/Form/InputNumber';
import numberRule from 'src/app-react/components/Form/Validations/numberRule';
import useProjecsConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

function AddedWaterOnSiteFormItem(props: InputFormItemProps) {
    const { units } = useProjecsConstantsState();
    const required = props.required ?? true;

    return (
        <InputFormItem
            name="addedWaterOnSite"
            label={t`Added water on site`}
            rules={[numberRule({ minOrEqual: 0, required })]}
            {...props}>
            <InputNumber
                controls={false}
                addonAfter={units.water}
                placeholder={t`Enter added water volume`}
                data-testid="addedWaterOnSite"
            />
        </InputFormItem>
    );
}

export default AddedWaterOnSiteFormItem;
