import { SupplierProducedConcrete } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { ConcreteLoadDataLite } from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';
import moment from 'moment';
import ConcreteLoadDataLiteFormFields from '../../models/ConcreteLoadDataLiteFormFields';
import { parseRequiredXf } from './fromConcreteTypeToFormFields';

export function fromSupplierConcreteLoadDataLiteToConcreteLoadDataLiteFormFields(
    supplierConcreteLoadDataLite: ConcreteLoadDataLite
): ConcreteLoadDataLiteFormFields {
    const producedConcrete: SupplierProducedConcrete =
        supplierConcreteLoadDataLite.producedConcrete as SupplierProducedConcrete;
    return {
        deliveryNoteNumber: supplierConcreteLoadDataLite.deliveryNoteNumber,
        number: supplierConcreteLoadDataLite.number,
        volume: supplierConcreteLoadDataLite.volume,
        orderVolume: supplierConcreteLoadDataLite.orderVolume,
        concretedVolume: supplierConcreteLoadDataLite.concretedVolume,
        truckNumber: supplierConcreteLoadDataLite.truckNumber,
        deliveryTime: supplierConcreteLoadDataLite.deliveryTime && moment(supplierConcreteLoadDataLite.deliveryTime),
        mixingPlant: supplierConcreteLoadDataLite.mixingPlant,
        supplierOrderId: supplierConcreteLoadDataLite.supplierOrderId,
        externalCustomProp: supplierConcreteLoadDataLite.externalCustomProp,
        compressiveStrengthClass: {
            requiredTypeOfConcreteType: producedConcrete?.typeOfConcrete,
            cylindricCompressiveStrength: producedConcrete?.cylindricCompressiveStrength,
            cubicCompressiveStrength: producedConcrete?.cubicCompressiveStrength
        },
        consistencyClass: {
            consistencyType: producedConcrete?.consistencyType,
            consistencyClass: producedConcrete?.consistencyClass
        },
        requiredX0: producedConcrete?.x0,
        requiredXc: producedConcrete?.xc,
        requiredXd: producedConcrete?.xd,
        requiredXf: parseRequiredXf(producedConcrete),
        requiredXa: producedConcrete?.xa,
        requiredXm: producedConcrete?.xm,
        requiredXs: producedConcrete?.xs,
        moistureClass: producedConcrete?.moistureClass,
        strengthDevelopmentClass: producedConcrete?.strengthDevelopmentClass,
        maxAggregateSize: producedConcrete?.maxAggregationSize,
        densityClass: producedConcrete?.densityClass,
        methodOfConcrete: producedConcrete?.methodOfConcrete,
        chlorideContentClass: producedConcrete?.chlorideContent,
        loadingStart: supplierConcreteLoadDataLite.loadingStart && moment(supplierConcreteLoadDataLite.loadingStart),
        arrivalAtConstructionSite:
            supplierConcreteLoadDataLite.arrivalAtConstructionSite &&
            moment(supplierConcreteLoadDataLite.arrivalAtConstructionSite),
        unloadingStart:
            supplierConcreteLoadDataLite.unloadingStart && moment(supplierConcreteLoadDataLite.unloadingStart),
        unloadingEnd: supplierConcreteLoadDataLite.unloadingEnd && moment(supplierConcreteLoadDataLite.unloadingEnd),
        signedOn: supplierConcreteLoadDataLite.signedOn && moment(supplierConcreteLoadDataLite.signedOn),
        loadingStartDate: moment(supplierConcreteLoadDataLite.loadingStart),
        arrivalAtConstructionSiteDate:
            supplierConcreteLoadDataLite.arrivalAtConstructionSite &&
            moment(supplierConcreteLoadDataLite.arrivalAtConstructionSite),
        unloadingStartDate:
            supplierConcreteLoadDataLite.unloadingStart && moment(supplierConcreteLoadDataLite.unloadingStart),
        unloadingEndDate:
            supplierConcreteLoadDataLite.unloadingEnd && moment(supplierConcreteLoadDataLite.unloadingEnd),
        signedOnDate: supplierConcreteLoadDataLite.signedOn && moment(supplierConcreteLoadDataLite.signedOn),
        weather: supplierConcreteLoadDataLite.weather,
        // concreteType:,  ## Missing this map on supplierConcreteLoadDataLite
        compressiveStrength: producedConcrete.compressiveStrength,
        slump: producedConcrete?.slump,
        maxWaterToAdd: producedConcrete?.maxWaterToAdd,
        locationName: supplierConcreteLoadDataLite.locationName,
        locationDescription: supplierConcreteLoadDataLite.locationDescription,
        locationType: supplierConcreteLoadDataLite.locationType,
        description: producedConcrete.description,
        locationDistanceToPlant: supplierConcreteLoadDataLite.locationDistanceToPlant,
        supplierName: supplierConcreteLoadDataLite.supplierName,
        supplierBusinessNumber: supplierConcreteLoadDataLite.supplierBusinessNumber,
        constructorName: supplierConcreteLoadDataLite.constructorName,
        constructorNumber: supplierConcreteLoadDataLite.constructorNumber,
        externalReference: supplierConcreteLoadDataLite.externalReference
    };
}
